<template>
  <v-container>
    <v-skeleton-loader
      type="table"
      v-if="!data_loaded"
      class="mt-10"
    ></v-skeleton-loader>
    <!--/ sekeletion -->

    <v-card elevation="0" class="mt-10" v-if="data_loaded">
      <v-card-title class="font-weight-bold">
        <h4 v-if="$store.state.user_menu_dashboard.is_admin != 1">الاقسام</h4>
        <h4 v-else>اقسام ({{ $route.query.store_name }})</h4>

        <v-spacer></v-spacer>
        <v-btn
          v-if="this.$store.state.user_menu_dashboard.is_admin != 1"
          to="/Category"
          color="primary"
          >اضافة قسم</v-btn
        >
      </v-card-title>
      <v-data-table
        :footer-props="$store.state.footer_props"
        :items="categories"
        :headers="headers"
        :mobile-breakpoint="0"
      >
      <template v-slot:[`item.category_name`]="{ item }">
        {{item.category_name[0].name}}
        </template>
        <!--/ logo -->
        <template v-slot:[`item.image`]="{ item }">
          <v-avatar width="50" class="my-2">
            <img v-if="item.image" :src="`${$store.state.def_path}/${item.image}`" />
            <img v-else  :src="`${$store.state.def_path}/none_img.png`" />  
          </v-avatar>
        </template>
        <!--/ logo -->
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn :to="{name:'category', params:{id: item.id} }" small icon>
            <v-icon small>edit</v-icon>
          </v-btn>
          <v-btn @click="open_delete_dialog(item.id)" small icon>
            <v-icon small>delete</v-icon>
          </v-btn>
        </template>
        <!--/ actions -->
      </v-data-table>
      <!--/ datatable -->
    </v-card>
    <!--/ card -->

    <v-dialog max-width="300" v-model="delete_dialog">
      <v-card>
        <v-card-title class="text-center d-block"> حذف القسم ؟ </v-card-title>
        <v-card-actions class="justify-center">
          <v-btn
            :loading="delete_loader"
            @click="delete_category()"
            depressed
            color="error"
            >حذف</v-btn
          >
          <v-btn @click="delete_dialog = false" depressed>الغاء</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--/ delete dialog -->

    <v-snackbar color="success" left v-model="snackbar_success">
      <p>تم حذف المحل</p>
      <template v-slot:action>
        <v-icon color="white" class="ms-3">check_circle</v-icon>
      </template>
    </v-snackbar>
  </v-container>
  <!--/ container -->
</template>

<script>
export default {
  name: "employees",

  data() {
    return {
      snackbar_success: false,
      delete_dialog: false,
      delete_loader: false,
      delete_id: null,
      data_loaded: false,
      categories: [],
      headers: [
        {
          value: "category_name",
          text: "اسم القسم",
          align: "center",
        },
        {
          value: "image",
          text: "صورة القسم",
          align: "center",
        },
        {
          value: "sort",
          text: "الترتيب",
          align: "center",
        },
      ],
      employee_index: -1,
      employee_data: null,
      attachments: [],
      dialog: false,
      files: [],
    };
  },
  methods: {
    get_categories() {
      this.$store
        .dispatch("public__request", {
          config: {
            url:
              this.$store.state.user_menu_dashboard.is_admin == 1
                ? `GetCategories/${this.$route.query.store}`
                : "Categories",
            method: "get",
          },
        })
        .then((res) => {
          this.data_loaded = true;
          this.categories = Object.assign([], res.data.data);
        }).catch(err=> {
        })
    },

    open_delete_dialog(id) {
      this.delete_id = id;
      this.delete_dialog = true;
    },
    delete_category() {
      if (!this.delete_loader) {
        this.delete_loader = true;
        this.$store
          .dispatch("public__request", {
            config: {
              url: `Categories/${this.delete_id}`,
              method: "delete",
            },
          })
          .then((res) => {
            let category_index = this.categories.findIndex(
              (e) => e.id == this.delete_id
            );

            this.categories.splice(category_index, 1);
            this.delete_loader = false;
            this.delete_dialog = false;
            this.snackbar_success = true;
          })
      }
    },
  },
  mounted() {
    if (this.$store.state.user_menu_dashboard.is_admin == 1) {
      if (!this.$route.query.store) {
        this.$router.push("/Stores");
      }
    } else {
      this.headers.splice(3, 0, {
        value: "actions",
        text: "أجراءات",
        align: "center",
      });
    }
          this.get_categories();
  },
};
</script>
